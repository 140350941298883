$primary: #d9b162;
$primary-opacity: rgba($primary, 0.75);

$white: #ffffff;
$white-2: #f6f8fc;
$blue: #467fd0;
$green: #22a07c;
$red: #da263e;

$light-grey: #dae3ef;
$light-grey-2: #9fa4b8;
$grey: #545454;
$dark-grey: #3d3d3d;

$border-radius: 0.6rem;

$margin: 1.3rem;
$placeholder-opacity: 0.6;
