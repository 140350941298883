@import '../../../../core/sass/variables';

.search-input {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  width: 45rem;
  background-color: $white;
  box-shadow: 0 0 1rem rgba($primary, 0.5);
  border: 0.1rem solid transparent;

  &:has(.search-input__input:focus) {
    border: 0.1rem solid $primary;
  }

  &__input {
    border: none;
    width: 100%;
    outline: none;
    font-size: 1.8rem;
    padding: 0.6rem 1rem;
    margin-left: 1rem;
  }

  &__icon {
    width: 3rem;
    fill: $primary;
    margin: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .search-input {
    width: 100%;
  }
}
