@import '../../sass/variables';

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 2.1rem;
  padding: 0.8rem 2rem;
  width: 100%;
  outline: none;
  white-space: nowrap;
}

.primary {
  background-color: $primary;
  color: #ffff;
  margin-top: 3rem;
  font-weight: bold;

  &:hover {
    transition: 0.5s;
    background-color: $primary-opacity;
  }
  &:active {
    background-color: $primary;
  }

  .icon {
    width: 2.5rem;
    margin: 0 0.5rem 0 0;
    fill: #fff;
  }
}

.yellow {
  background-color: $primary;
  color: #ffff;
  font-size: 1.9rem;

  &:hover {
    transition: 0.5s;
    background-color: $primary-opacity;
  }
  &:active {
    background-color: $primary;
  }

  .icon {
    width: 1.5rem;
    margin: 0 0.7rem 0 0;
    fill: #fff;
  }
}

.green {
  background-color: $green;
  color: #ffff;
  font-size: 1.9rem;
  &:hover {
    transition: 0.5s;
    background-color: rgba($green, 0.75);
  }
  &:active {
    background-color: $green;
  }

  .icon {
    width: 1.5rem;
    margin: 0 0.7rem 0 0;
    fill: #fff;
  }
}
.red {
  background-color: $red;
  color: #ffff;
  font-size: 1.9rem;
  &:hover {
    transition: 0.5s;
    background-color: rgba($red, 0.75);
  }
  &:active {
    background-color: $red;
  }

  .icon {
    width: 1.8rem;
    margin: 0 0.7rem 0 0;
    fill: #fff;
  }
}
.blue {
  background-color: $blue;
  color: #ffff;
  font-size: 1.9rem;
  &:hover {
    transition: 0.5s;
    background-color: rgba($blue, 0.75);
  }
  &:active {
    background-color: $blue;
  }

  .icon {
    width: 1.5rem;
    margin: 0 0.7rem 0 0;
    fill: #fff;
  }
}

.light-grey {
  background-color: $light-grey;
  color: $dark-grey;
  font-size: 1.9rem;

  &:hover {
    transition: 0.5s;
    background-color: rgba($light-grey, 0.75);
  }
  &:active {
    background-color: $light-grey;
  }

  .icon {
    width: 1.8rem;
    margin: 0 0.7rem 0 0;
    fill: $dark-grey;
  }
}
