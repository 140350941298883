@import '../../../../../core/sass/variables';

.destination {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 17rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  &__name {
    font-size: 2.7rem;
    font-weight: 500;
    margin-top: 1rem;
  }
}
