@import '../../sass/variables';

.custom-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin;

  &__label-and-icon {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &__label {
      font-size: 1.9rem;
      color: $grey;
      font-weight: 500;
    }

    &__icon {
      width: 1.7rem;
      margin-left: 0.6rem;
      fill: #545454;
    }
  }

  &__custom-input {
    width: 100%;
    border-radius: $border-radius;
    border: 0.1rem solid $primary;
    font-size: 1.7rem;
    padding: 0.8rem 1rem;

    &:focus {
      outline: none;
    }
    &::placeholder {
      opacity: $placeholder-opacity;
    }
    &:-ms-input-placeholder {
      opacity: $placeholder-opacity;
    }

    &::-ms-input-placeholder {
      opacity: $placeholder-opacity;
    }
  }
}
