@import '../../../core/sass/variables';

.list-view {
  padding: 2rem 1rem;
  width: 100%;
  &__station-name {
    text-align: center;
    font-weight: bold;
  }
  &__leave-date {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  &__item {
    width: 100%;
    border-bottom: 0.1rem solid $primary;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }
}
