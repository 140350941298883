@import '../../../../core/sass/variables';

.main-layout {
  padding: 0 1rem;
  &__title {
    // font-size: 4rem;
    font-weight: bold;
    padding: 1rem 0 2rem 0;
  }
}
